<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h6 slot="start">Send Broadcast Message</h6>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <div v-if="isLoading" class="text-center pad-ten full-height">
        <div class="centerItems text-muted display-flex valign full-height full-width">
          <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
        </div>
      </div>
      <ion-card color="warning" v-if="inactivePatients" class="margin-lr-five">
        <ion-row class="ion-align-items-center">
          <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
            <IconWarning />
          </ion-col>
          <ion-col>
            <ion-row class="text-bold"> {{ inactivePatients }} Patients: Not Active in LumerisEngage</ion-row>
          </ion-col>
        </ion-row>
      </ion-card>
      <ion-card color="warning" v-if="noValidSmsPatients" class="margin-lr-five">
        <ion-row class="ion-align-items-center">
          <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
            <IconWarning />
          </ion-col>
          <ion-col>
            <ion-row class="text-bold"> {{ noValidSmsPatients }} Patients: No valid SMS phone number</ion-row>
          </ion-col>
        </ion-row>
      </ion-card>
      <ion-card color="warning" v-if="noneOrgPatients" class="margin-lr-five">
        <ion-row class="ion-align-items-center">
          <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
            <IconWarning />
          </ion-col>
          <ion-col>
            <ion-row class="text-bold"> {{ noneOrgPatients }} Patients: Not in your organization</ion-row>
          </ion-col>
        </ion-row>
      </ion-card>
      <ion-card color="warning" v-if="duplicatePatients" class="margin-lr-five">
        <ion-row class="ion-align-items-center">
          <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
            <IconWarning />
          </ion-col>
          <ion-col>
            <ion-row class="text-bold"> {{ duplicatePatients }} Patients: Duplicate ID</ion-row>
          </ion-col>
        </ion-row>
      </ion-card>

      <div class="totals-message">{{ erredRecordsNumber }} Errors- {{ sendablePatients.length }} Patients Successfully Selected</div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col class="pad-right-twenty full-height overflow-y-scroll">
            <button @click="closeModal()" class="prime-button button-block button-secondary">
              <span>Cancel</span>
            </button>
          </ion-col>
          <ion-col>
            <button @click="messagePatients" v-if="sendablePatients.length" class="prime-button button-block">
              <span>Message {{ sendablePatients.length }} Patients </span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import IconWarning from "@/components/Global/Icons/IconWarning";
import router from "@/router";

export default {
  name: "ModalBroadcastMessageRoster",
  props: {
    fetchSelectedPatients: Function
  },
  components: {
    IconWarning
  },
  data() {
    return {
      inactivePatients: 0,
      noValidSmsPatients: 0,
      noneOrgPatients: 0,
      duplicatePatients: 0,
      sendablePatients: [],
      erredRecordsNumber: 0,
      isLoading: false
    };
  },
  async mounted() {
    this.isLoading = true;
    try {
      const patients = await this.fetchSelectedPatients(["active", "sms", "organizationid", "mrn"]);
      const uniquePatients = patients.filter((patient, index, self) => {
        return self.findIndex(p => p.mrn === patient.mrn) === index && self.filter(p => p.mrn === patient.mrn).length === 1;
      });
      this.inactivePatients = uniquePatients.filter(patient => !patient.active).length;
      this.noValidSmsPatients = uniquePatients.filter(patient => !patient.sms).length;
      this.noneOrgPatients = uniquePatients.filter(patient => !patient.organizationid).length;
      this.duplicatePatients = patients.length - uniquePatients.length;
      this.sendablePatients = uniquePatients.filter(patient => patient.active && patient.sms && patient.organizationid).map(patient => patient.mrn);
      this.erredRecordsNumber = this.inactivePatients + this.noValidSmsPatients + this.noneOrgPatients + this.duplicatePatients;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  },
  methods: {
    messagePatients() {
      router.push({ name: "broadcast-message", params: { mode: "bulk", bulkedMrns: this.sendablePatients } });
      this.closeModal();
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.prime-modal-content {
  border: none;
  padding: 0 20px 5px;
}

ion-footer {
  padding: 20px;
}

.totals-message {
  position: absolute;
  bottom: 20px;
  font-weight: bold;
}
</style>
