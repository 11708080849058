<template>
  <PrimePage titleAlignment="left" :loading="isLoading">
    <ion-row class="full-height">
      <ion-col>
        <ion-content>
          <div class="prime-full-width-container prime-roster-container">
            <ion-row class="display-flex valign">
              <ion-col>
                <h2 class="view-title">Patient Roster</h2>
              </ion-col>
              <ion-col></ion-col>
              <ion-col size="auto" v-if="$can(I.ADD_PATIENT)">
                <button class="prime-button" @click="showAddPatientPopover">
                  <IconPersonAdd class="icon-small" />Add Patient
                </button>
              </ion-col>
              <ion-col size="auto" v-else>
                <button class="prime-button disabled"
                  v-tooltip.top-start="{ content: $orgMissingPermissions('The Add Patient feature') }">
                  <IconPersonAdd class="icon-small" />Add Patient
                </button>
              </ion-col>
            </ion-row>
            <ion-card color="white">
              <ion-card-header>
                <div class="searchBox">
                  <form>
                    <ion-grid>
                      <ion-row class="ion-justify-content-end">
                        <ion-col size="auto" class="bottom-border">
                          <button class="prime-button button-secondary preset-button" style="position:relative"
                            type="button" @click="showPresetsPopover">
                            <span>Presets: {{ gridKey || "Default" }}</span>
                            <IconCarrot class="icon-small icon-grey preset-carrot valign" />
                          </button>
                        </ion-col>
                        <ion-col size="auto" class="pad-left-ten">
                          <button class="prime-button button-secondary" type="button" @click="clearFilters">
                            <span>Clear Filters</span>
                          </button>
                        </ion-col>
                        <ion-col size="auto" class="pad-left-ten">
                          <button class="prime-button button-secondary prime-roster-download-button"
                            @click="exportRoster" type="button" :class="{ 'button-pending': isDownloading }">
                            <ion-progress-bar v-if="isDownloading" :value="downloadProgress"></ion-progress-bar>
                            <span v-else>
                              <IconDownload class="icon-small" />Download Roster
                            </span>
                          </button>
                        </ion-col>
                        <ion-col v-if="$can(I.CAN_ACCESS_BROADCAST_MESSAGING)" size="auto" class="pad-left-ten">
                          <button class="prime-button button-primary" type="button" @click="viewBulkActions">Bulk
                            Action</button>
                        </ion-col>
                        <ion-col v-else size="auto" class="pad-left-ten disabled-item-col"
                          v-tooltip.top-start="{ content: $orgMissingPermissions('The Broadcast Messaging feature') }">
                          <button class="prime-button button-primary disabled" type="button">Bulk
                            Action</button>
                        </ion-col>
                      </ion-row>
                    </ion-grid>

                  </form>
                </div>


              </ion-card-header>

              <div v-if="this.selectedFilterList.length > 0" ref="currentFilters" class="currentFiltersDiv">

                <ion-row class="bottom-divider-thinnest pad-tb-five" />

                <ion-row
                  class="margin-left-twenty margin-right-twenty margin-top-ten margin-bottom-five"
                >
                  <div class="chips-container">
                    <div class="chips-heading">Current Filters:</div>
                    <Chips :chipsData="this.selectedFilterList" @chip-removed="handleChipRemoved" />
                  </div>
                </ion-row>

              </div>

              <div>
                <PrimeGrid view="roster" :key="gridKey" @grid-ready="onGridReady" @export-progress="onProgressUpdate"
                  @export-complete="onExportComplete" @filter-selected="onAGGridFilterSelected"
                  :divHeight="divHeight" />
              </div>
            </ion-card>
          </div>
        </ion-content>
      </ion-col>
    </ion-row>
  </PrimePage>
</template>

<script>
import { addIcons } from "ionicons";
import { add, more } from "ionicons/icons";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";
import IconCarrot from "@/components/Global/Icons/IconCarrot";
import popoverRosterPatient from "@/components/Roster/PopoverRosterPatient";
import PopoverBulkActions from "@/components/Roster/PopoverBulkActions";
import PrimePage from "@/components/Global/PrimePage";
import IconDownload from "@/components/Global/Icons/IconDownload";
import PrimeGrid from "@/components/Global/PrimeGrid";
import Chips from "@/components/Global/Chips";
import { EventBus } from "@/services/Events.js";
import PopoverRosterPresets from "@/components/Roster/PopoverRosterPresets";

addIcons({
  "md-add": add.md,
  "md-more": more.md
});

export default {
  name: "Roster",
  components: {
    PrimePage,
    IconPersonAdd,
    IconDownload,
    PrimeGrid,
    Chips,
    IconCarrot
  },
  data() {
    return {
      isLoading: true,
      isDownloading: false,
      downloadProgress: null,
      selectedFilterList: [],
      divHeight: 0,
      grid: null,
      gridKey: ""
    };
  },
  beforeMount() {
    this.gridKey = window.localStorage.getItem("currentPresetName");
  },
  mounted() {
    EventBus.$on("presetUpdated", presetName => {
      this.gridKey = presetName;
      if (this.gridKey === "") {
        this.selectedFilterList = [];
        this.divHeight = 0;
      }
    });
  },
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    }
  },
  watch: {
    selectedFilterList() {
      this.$nextTick(() => {
        this.getDivHeight();
      });
    },
  },
  methods: {
    getDivHeight() {
      if (this.selectedFilterList.length === 0) {
        this.divHeight = 0
      } else {
        const myDiv = this.$refs.currentFilters;
        if (myDiv) {
          this.divHeight = myDiv.clientHeight;
          if (this.divHeight > 0 && this.divHeight < 30) {
            this.divHeight = 80;
          } else {
            this.divHeight = this.divHeight + 20;
          }
        }
      }
      
      EventBus.$emit("gridHeight", this.divHeight);
    },
    onGridReady(grid) {
      this.isLoading = false;
      this.grid = grid;
    },
    clearFilters() {
      this.grid.api.setFilterModel(null);
      this.selectedFilterList = [];
      EventBus.$emit("rosterFiltersCleared");
      this.divHeight = 0;
    },
    handleChipRemoved(removedSelectedFilterIdentifier) {
      // Find the index of the filter with the specified key
      const filterIndex = this.findFilterIndexByKey(removedSelectedFilterIdentifier, this.selectedFilterList);
      if (filterIndex !== -1) {
        // Remove the unselected filter from the array
        const updatedSelectedFilters = [...this.selectedFilterList];
        updatedSelectedFilters.splice(filterIndex, 1);
        // Update the selected filter data property
        this.selectedFilterList = updatedSelectedFilters;
        this.grid.api.destroyFilter(removedSelectedFilterIdentifier);
        if (removedSelectedFilterIdentifier === 'quality_considerations' || removedSelectedFilterIdentifier === 'diagnosis_considerations'
          || removedSelectedFilterIdentifier === 'reported_conditions') {
          EventBus.$emit("advancedFiltersCleared", removedSelectedFilterIdentifier);
        }

      }
      EventBus.$emit("gridHeight", 80);
    },
    findFilterIndexByKey(key, filterArray) {
      return filterArray.findIndex((chip) => chip.key === key);
    },
    onAGGridFilterSelected(selectedFilter) {
      this.selectedFilterList = [];
      if(!selectedFilter) {
        return;
      }
      const updatedSelectedFiltersArray = [...this.selectedFilterList];
      Object.keys(selectedFilter).forEach((key) => {
        const existingSelectedFilterIndex = updatedSelectedFiltersArray.findIndex((filter) => filter.key === key);
        if (existingSelectedFilterIndex !== -1) {
          // Update the existing filter
          updatedSelectedFiltersArray[existingSelectedFilterIndex] = { key, ...selectedFilter[key] };
        } else {
          // Add a new filter if it doesn't exist
          updatedSelectedFiltersArray.push({ key, ...selectedFilter[key] });
        }
      });
      // Update the selected filter list data property
      this.selectedFilterList = updatedSelectedFiltersArray;
    },
    showPresetsPopover(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverRosterPresets,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              presetName: this.gridKey,
              professionalId: this.currentProfessional.professional.id
            }
          }
        })
        .then(p => p.present());
    },
    showAddPatientPopover(ev) {
      this.$ionic.popoverController
        .create({
          component: popoverRosterPatient,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm"
        })
        .then(p => p.present());
    },
    async exportRoster() {
      this.isDownloading = true;
      await this.grid.export();
    },
    onProgressUpdate(progress) {
      this.downloadProgress = progress;
    },
    onExportComplete() {
      this.isDownloading = false;
      this.downloadProgress = 0;
    },
    viewBulkActions(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverBulkActions,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            propsData: {
              fetchSelectedPatients: this.grid.fetchSelectedRows
            }
          }
        })
        .then(p => p.present());
    }
  }
};
</script>

<style scoped>
/* -------------------------------- */
/* Patient Roster ----------------- */
/* -------------------------------- */

.bottom-border {
  border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.prime-roster-container {
  display:flex;
  flex-direction: column;
  height: 90vh;
  margin-bottom: 0;
}

.prime-roster-container ion-card {
  /* height: calc(100% - 130px); */
  margin: 10px 0 20px 0 !important;
}

.prime-roster-container ion-card ion-card-header {
  padding: 5px;
}

/* Patient Roster Options Buttons -- */
.prime-roster-options-button-container button.prime-button.button-clear {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
}

.prime-roster-options-button-container button ion-icon {
  margin-right: 0;
}

.prime-roster-download-button {
  min-width: 153px;
}

.roster-grid-container {
  height: calc(100% - 80px);
}

.preset-button {
  width: 20rem;
  text-align: left;
  background-color: white !important;
  border: 2px solid var(--color-secondary-button);
  color: lightgray !important;
}

.preset-carrot {
  position: absolute;
  right: 0;
  top: 10px;
  transform: rotate(90deg);
}

.chips-heading {
  font-size: 15px;
  font-weight: 700;
  margin-top: 2px;
  white-space: nowrap;
}

.chips-container {
  display: flex;
  align-items: flex-start;
}

button.prime-button.button-secondary.preset-button svg {
  fill: lightgray;
}
</style>
